/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FundraiserTeam
 */
export interface FundraiserTeam {
    /**
     * 
     * @type {string}
     * @memberof FundraiserTeam
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof FundraiserTeam
     */
    readonly amount_raised: number;
}

/**
 * Check if a given object implements the FundraiserTeam interface.
 */
export function instanceOfFundraiserTeam(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "amount_raised" in value;

    return isInstance;
}

export function FundraiserTeamFromJSON(json: any): FundraiserTeam {
    return FundraiserTeamFromJSONTyped(json, false);
}

export function FundraiserTeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundraiserTeam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'amount_raised': json['amount_raised'],
    };
}

export function FundraiserTeamToJSON(value?: FundraiserTeam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}

