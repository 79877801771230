/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FundraiserCodeValidationResultMetadata } from './FundraiserCodeValidationResultMetadata';
import {
    FundraiserCodeValidationResultMetadataFromJSON,
    FundraiserCodeValidationResultMetadataFromJSONTyped,
    FundraiserCodeValidationResultMetadataToJSON,
} from './FundraiserCodeValidationResultMetadata';

/**
 * 
 * @export
 * @interface FundraiserCodeValidationResult
 */
export interface FundraiserCodeValidationResult {
    /**
     * 
     * @type {boolean}
     * @memberof FundraiserCodeValidationResult
     */
    code_valid: boolean;
    /**
     * 
     * @type {string}
     * @memberof FundraiserCodeValidationResult
     */
    error_message: string;
    /**
     * 
     * @type {FundraiserCodeValidationResultMetadata}
     * @memberof FundraiserCodeValidationResult
     */
    metadata: FundraiserCodeValidationResultMetadata | null;
}

/**
 * Check if a given object implements the FundraiserCodeValidationResult interface.
 */
export function instanceOfFundraiserCodeValidationResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code_valid" in value;
    isInstance = isInstance && "error_message" in value;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function FundraiserCodeValidationResultFromJSON(json: any): FundraiserCodeValidationResult {
    return FundraiserCodeValidationResultFromJSONTyped(json, false);
}

export function FundraiserCodeValidationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundraiserCodeValidationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code_valid': json['code_valid'],
        'error_message': json['error_message'],
        'metadata': FundraiserCodeValidationResultMetadataFromJSON(json['metadata']),
    };
}

export function FundraiserCodeValidationResultToJSON(value?: FundraiserCodeValidationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code_valid': value.code_valid,
        'error_message': value.error_message,
        'metadata': FundraiserCodeValidationResultMetadataToJSON(value.metadata),
    };
}

