/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { City } from './City';
import {
    CityFromJSON,
    CityFromJSONTyped,
    CityToJSON,
} from './City';
import type { DevicePlatformEnum } from './DevicePlatformEnum';
import {
    DevicePlatformEnumFromJSON,
    DevicePlatformEnumFromJSONTyped,
    DevicePlatformEnumToJSON,
} from './DevicePlatformEnum';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';
import type { PatchedUserDayPass } from './PatchedUserDayPass';
import {
    PatchedUserDayPassFromJSON,
    PatchedUserDayPassFromJSONTyped,
    PatchedUserDayPassToJSON,
} from './PatchedUserDayPass';
import type { PatchedUserPendingDonation } from './PatchedUserPendingDonation';
import {
    PatchedUserPendingDonationFromJSON,
    PatchedUserPendingDonationFromJSONTyped,
    PatchedUserPendingDonationToJSON,
} from './PatchedUserPendingDonation';
import type { RedemptionCodeType } from './RedemptionCodeType';
import {
    RedemptionCodeTypeFromJSON,
    RedemptionCodeTypeFromJSONTyped,
    RedemptionCodeTypeToJSON,
} from './RedemptionCodeType';
import type { SexEnum } from './SexEnum';
import {
    SexEnumFromJSON,
    SexEnumFromJSONTyped,
    SexEnumToJSON,
} from './SexEnum';
import type { UtmMediumEnum } from './UtmMediumEnum';
import {
    UtmMediumEnumFromJSON,
    UtmMediumEnumFromJSONTyped,
    UtmMediumEnumToJSON,
} from './UtmMediumEnum';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly full_name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly short_name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly phone: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    birthday: Date;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    readonly age: number;
    /**
     * 
     * @type {SexEnum}
     * @memberof User
     */
    sex: SexEnum | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly avatar_url: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    registered?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    date_joined?: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    last_app_open?: Date | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof User
     */
    readonly feature_flags: { [key: string]: boolean; };
    /**
     * 
     * @type {number}
     * @memberof User
     */
    current_city?: number | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    home_city: number;
    /**
     * 
     * @type {Array<City>}
     * @memberof User
     */
    readonly cities: Array<City>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly is_pro: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly has_active_day_pass: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly has_pending_donation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly has_active_membership: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly has_past_due_subscription: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly has_upcoming_subscription: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly free_promo_active: boolean;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    readonly free_promo_end_date: Date;
    /**
     * 
     * @type {Array<Organization>}
     * @memberof User
     */
    readonly organizations: Array<Organization>;
    /**
     * 
     * @type {UtmMediumEnum}
     * @memberof User
     */
    utm_medium?: UtmMediumEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    set_redemption_code?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly accepted_referral_code: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly accepted_referral_name: string;
    /**
     * 
     * @type {PatchedUserDayPass}
     * @memberof User
     */
    day_pass: PatchedUserDayPass | null;
    /**
     * 
     * @type {PatchedUserPendingDonation}
     * @memberof User
     */
    pending_donation: PatchedUserPendingDonation | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly referral_code: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    custom_referral_code?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly trial_available: boolean;
    /**
     * A user can cancel a subscription if they have an active or trialing subscription that has not been cancelled.
     * @type {boolean}
     * @memberof User
     */
    readonly cancel_available: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    use_metric?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    participate_in_contests?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    readonly favorite_businesses: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    readonly deal_requests: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof User
     */
    readonly favorite_menu_items: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    readonly redemption_count: number;
    /**
     * Get any active redemption locks and their expiration datetimes.
     * 
     * Any redemptions within the last 24 hours are possibilities. The latest redemption takes precedence.
     * @type {{ [key: string]: Date; }}
     * @memberof User
     */
    readonly redemption_locks: { [key: string]: Date; };
    /**
     * 
     * @type {string}
     * @memberof User
     */
    device_token?: string;
    /**
     * 
     * @type {DevicePlatformEnum}
     * @memberof User
     */
    device_platform?: DevicePlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly intercom_hash: string;
    /**
     * 
     * @type {{ [key: string]: RedemptionCodeType; }}
     * @memberof User
     */
    readonly code_prefix_map: { [key: string]: RedemptionCodeType; };
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "full_name" in value;
    isInstance = isInstance && "short_name" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "birthday" in value;
    isInstance = isInstance && "age" in value;
    isInstance = isInstance && "sex" in value;
    isInstance = isInstance && "avatar_url" in value;
    isInstance = isInstance && "feature_flags" in value;
    isInstance = isInstance && "home_city" in value;
    isInstance = isInstance && "cities" in value;
    isInstance = isInstance && "is_pro" in value;
    isInstance = isInstance && "has_active_day_pass" in value;
    isInstance = isInstance && "has_pending_donation" in value;
    isInstance = isInstance && "has_active_membership" in value;
    isInstance = isInstance && "has_past_due_subscription" in value;
    isInstance = isInstance && "has_upcoming_subscription" in value;
    isInstance = isInstance && "free_promo_active" in value;
    isInstance = isInstance && "free_promo_end_date" in value;
    isInstance = isInstance && "organizations" in value;
    isInstance = isInstance && "accepted_referral_code" in value;
    isInstance = isInstance && "accepted_referral_name" in value;
    isInstance = isInstance && "day_pass" in value;
    isInstance = isInstance && "pending_donation" in value;
    isInstance = isInstance && "referral_code" in value;
    isInstance = isInstance && "trial_available" in value;
    isInstance = isInstance && "cancel_available" in value;
    isInstance = isInstance && "favorite_businesses" in value;
    isInstance = isInstance && "deal_requests" in value;
    isInstance = isInstance && "favorite_menu_items" in value;
    isInstance = isInstance && "redemption_count" in value;
    isInstance = isInstance && "redemption_locks" in value;
    isInstance = isInstance && "intercom_hash" in value;
    isInstance = isInstance && "code_prefix_map" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'full_name': json['full_name'],
        'short_name': json['short_name'],
        'phone': json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'birthday': (new Date(json['birthday'])),
        'age': json['age'],
        'sex': SexEnumFromJSON(json['sex']),
        'avatar_url': json['avatar_url'],
        'registered': !exists(json, 'registered') ? undefined : json['registered'],
        'date_joined': !exists(json, 'date_joined') ? undefined : (new Date(json['date_joined'])),
        'last_app_open': !exists(json, 'last_app_open') ? undefined : (json['last_app_open'] === null ? null : new Date(json['last_app_open'])),
        'feature_flags': json['feature_flags'],
        'current_city': !exists(json, 'current_city') ? undefined : json['current_city'],
        'home_city': json['home_city'],
        'cities': ((json['cities'] as Array<any>).map(CityFromJSON)),
        'is_pro': json['is_pro'],
        'has_active_day_pass': json['has_active_day_pass'],
        'has_pending_donation': json['has_pending_donation'],
        'has_active_membership': json['has_active_membership'],
        'has_past_due_subscription': json['has_past_due_subscription'],
        'has_upcoming_subscription': json['has_upcoming_subscription'],
        'free_promo_active': json['free_promo_active'],
        'free_promo_end_date': (new Date(json['free_promo_end_date'])),
        'organizations': ((json['organizations'] as Array<any>).map(OrganizationFromJSON)),
        'utm_medium': !exists(json, 'utm_medium') ? undefined : UtmMediumEnumFromJSON(json['utm_medium']),
        'set_redemption_code': !exists(json, 'set_redemption_code') ? undefined : json['set_redemption_code'],
        'accepted_referral_code': json['accepted_referral_code'],
        'accepted_referral_name': json['accepted_referral_name'],
        'day_pass': PatchedUserDayPassFromJSON(json['day_pass']),
        'pending_donation': PatchedUserPendingDonationFromJSON(json['pending_donation']),
        'referral_code': json['referral_code'],
        'custom_referral_code': !exists(json, 'custom_referral_code') ? undefined : json['custom_referral_code'],
        'trial_available': json['trial_available'],
        'cancel_available': json['cancel_available'],
        'use_metric': !exists(json, 'use_metric') ? undefined : json['use_metric'],
        'participate_in_contests': !exists(json, 'participate_in_contests') ? undefined : json['participate_in_contests'],
        'favorite_businesses': json['favorite_businesses'],
        'deal_requests': json['deal_requests'],
        'favorite_menu_items': json['favorite_menu_items'],
        'redemption_count': json['redemption_count'],
        'redemption_locks': json['redemption_locks'],
        'device_token': !exists(json, 'device_token') ? undefined : json['device_token'],
        'device_platform': !exists(json, 'device_platform') ? undefined : DevicePlatformEnumFromJSON(json['device_platform']),
        'intercom_hash': json['intercom_hash'],
        'code_prefix_map': (mapValues(json['code_prefix_map'], RedemptionCodeTypeFromJSON)),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.first_name,
        'last_name': value.last_name,
        'email': value.email,
        'birthday': (value.birthday.toISOString().substr(0,10)),
        'sex': SexEnumToJSON(value.sex),
        'registered': value.registered,
        'date_joined': value.date_joined === undefined ? undefined : (value.date_joined.toISOString()),
        'last_app_open': value.last_app_open === undefined ? undefined : (value.last_app_open === null ? null : value.last_app_open.toISOString()),
        'current_city': value.current_city,
        'home_city': value.home_city,
        'utm_medium': UtmMediumEnumToJSON(value.utm_medium),
        'set_redemption_code': value.set_redemption_code,
        'day_pass': PatchedUserDayPassToJSON(value.day_pass),
        'pending_donation': PatchedUserPendingDonationToJSON(value.pending_donation),
        'custom_referral_code': value.custom_referral_code,
        'use_metric': value.use_metric,
        'participate_in_contests': value.participate_in_contests,
        'device_token': value.device_token,
        'device_platform': DevicePlatformEnumToJSON(value.device_platform),
    };
}

