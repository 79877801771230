/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FundraiserDonation } from './FundraiserDonation';
import {
    FundraiserDonationFromJSON,
    FundraiserDonationFromJSONTyped,
    FundraiserDonationToJSON,
} from './FundraiserDonation';

/**
 * 
 * @export
 * @interface PatchedUserPendingDonation
 */
export interface PatchedUserPendingDonation {
    /**
     * 
     * @type {string}
     * @memberof PatchedUserPendingDonation
     */
    readonly organization_name: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserPendingDonation
     */
    readonly fundraiser_name: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserPendingDonation
     */
    readonly team_name: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchedUserPendingDonation
     */
    readonly start_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedUserPendingDonation
     */
    readonly end_at: Date;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserPendingDonation
     */
    readonly redemption_code: string;
}

/**
 * Check if a given object implements the PatchedUserPendingDonation interface.
 */
export function instanceOfPatchedUserPendingDonation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organization_name" in value;
    isInstance = isInstance && "fundraiser_name" in value;
    isInstance = isInstance && "team_name" in value;
    isInstance = isInstance && "start_at" in value;
    isInstance = isInstance && "end_at" in value;
    isInstance = isInstance && "redemption_code" in value;

    return isInstance;
}

export function PatchedUserPendingDonationFromJSON(json: any): PatchedUserPendingDonation {
    return PatchedUserPendingDonationFromJSONTyped(json, false);
}

export function PatchedUserPendingDonationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedUserPendingDonation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization_name': json['organization_name'],
        'fundraiser_name': json['fundraiser_name'],
        'team_name': json['team_name'],
        'start_at': (new Date(json['start_at'])),
        'end_at': (new Date(json['end_at'])),
        'redemption_code': json['redemption_code'],
    };
}

export function PatchedUserPendingDonationToJSON(value?: PatchedUserPendingDonation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

