/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `fundraiser` - Fundraiser
 * * `other` - Other
 * @export
 */
export const SecretTypeEnum = {
    fundraiser: 'fundraiser',
    other: 'other'
} as const;
export type SecretTypeEnum = typeof SecretTypeEnum[keyof typeof SecretTypeEnum];


export function SecretTypeEnumFromJSON(json: any): SecretTypeEnum {
    return SecretTypeEnumFromJSONTyped(json, false);
}

export function SecretTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecretTypeEnum {
    return json as SecretTypeEnum;
}

export function SecretTypeEnumToJSON(value?: SecretTypeEnum | null): any {
    return value as any;
}

