/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FundraiserTeam } from './FundraiserTeam';
import {
    FundraiserTeamFromJSON,
    FundraiserTeamFromJSONTyped,
    FundraiserTeamToJSON,
} from './FundraiserTeam';

/**
 * 
 * @export
 * @interface Fundraiser
 */
export interface Fundraiser {
    /**
     * 
     * @type {string}
     * @memberof Fundraiser
     */
    readonly organization_name: string;
    /**
     * 
     * @type {string}
     * @memberof Fundraiser
     */
    readonly name: string;
    /**
     * 
     * @type {Date}
     * @memberof Fundraiser
     */
    readonly start_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof Fundraiser
     */
    readonly end_at: Date;
    /**
     * 
     * @type {number}
     * @memberof Fundraiser
     */
    readonly amount_raised: number;
    /**
     * 
     * @type {string}
     * @memberof Fundraiser
     */
    readonly donation_goal: string;
    /**
     * 
     * @type {boolean}
     * @memberof Fundraiser
     */
    readonly is_active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Fundraiser
     */
    readonly is_fully_funded: boolean;
    /**
     * 
     * @type {Array<FundraiserTeam>}
     * @memberof Fundraiser
     */
    readonly teams: Array<FundraiserTeam>;
}

/**
 * Check if a given object implements the Fundraiser interface.
 */
export function instanceOfFundraiser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organization_name" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "start_at" in value;
    isInstance = isInstance && "end_at" in value;
    isInstance = isInstance && "amount_raised" in value;
    isInstance = isInstance && "donation_goal" in value;
    isInstance = isInstance && "is_active" in value;
    isInstance = isInstance && "is_fully_funded" in value;
    isInstance = isInstance && "teams" in value;

    return isInstance;
}

export function FundraiserFromJSON(json: any): Fundraiser {
    return FundraiserFromJSONTyped(json, false);
}

export function FundraiserFromJSONTyped(json: any, ignoreDiscriminator: boolean): Fundraiser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization_name': json['organization_name'],
        'name': json['name'],
        'start_at': (new Date(json['start_at'])),
        'end_at': (new Date(json['end_at'])),
        'amount_raised': json['amount_raised'],
        'donation_goal': json['donation_goal'],
        'is_active': json['is_active'],
        'is_fully_funded': json['is_fully_funded'],
        'teams': ((json['teams'] as Array<any>).map(FundraiserTeamFromJSON)),
    };
}

export function FundraiserToJSON(value?: Fundraiser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

