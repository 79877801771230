/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FundraiserCodeValidation
 */
export interface FundraiserCodeValidation {
    /**
     * 
     * @type {string}
     * @memberof FundraiserCodeValidation
     */
    fundraiser_code: string;
}

/**
 * Check if a given object implements the FundraiserCodeValidation interface.
 */
export function instanceOfFundraiserCodeValidation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fundraiser_code" in value;

    return isInstance;
}

export function FundraiserCodeValidationFromJSON(json: any): FundraiserCodeValidation {
    return FundraiserCodeValidationFromJSONTyped(json, false);
}

export function FundraiserCodeValidationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundraiserCodeValidation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fundraiser_code': json['fundraiser_code'],
    };
}

export function FundraiserCodeValidationToJSON(value?: FundraiserCodeValidation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fundraiser_code': value.fundraiser_code,
    };
}

