/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HostCodeValidationResultMetadata } from './HostCodeValidationResultMetadata';
import {
    HostCodeValidationResultMetadataFromJSON,
    HostCodeValidationResultMetadataFromJSONTyped,
    HostCodeValidationResultMetadataToJSON,
} from './HostCodeValidationResultMetadata';

/**
 * 
 * @export
 * @interface HostCodeValidationResult
 */
export interface HostCodeValidationResult {
    /**
     * 
     * @type {boolean}
     * @memberof HostCodeValidationResult
     */
    code_valid: boolean;
    /**
     * 
     * @type {string}
     * @memberof HostCodeValidationResult
     */
    error_message: string;
    /**
     * 
     * @type {HostCodeValidationResultMetadata}
     * @memberof HostCodeValidationResult
     */
    metadata: HostCodeValidationResultMetadata | null;
}

/**
 * Check if a given object implements the HostCodeValidationResult interface.
 */
export function instanceOfHostCodeValidationResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code_valid" in value;
    isInstance = isInstance && "error_message" in value;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function HostCodeValidationResultFromJSON(json: any): HostCodeValidationResult {
    return HostCodeValidationResultFromJSONTyped(json, false);
}

export function HostCodeValidationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostCodeValidationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code_valid': json['code_valid'],
        'error_message': json['error_message'],
        'metadata': HostCodeValidationResultMetadataFromJSON(json['metadata']),
    };
}

export function HostCodeValidationResultToJSON(value?: HostCodeValidationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code_valid': value.code_valid,
        'error_message': value.error_message,
        'metadata': HostCodeValidationResultMetadataToJSON(value.metadata),
    };
}

