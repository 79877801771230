/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AppSecretPasswordValidation,
  AppSecretPasswordValidationResult,
} from '../models';
import {
    AppSecretPasswordValidationFromJSON,
    AppSecretPasswordValidationToJSON,
    AppSecretPasswordValidationResultFromJSON,
    AppSecretPasswordValidationResultToJSON,
} from '../models';

export interface SecretsValidatePasswordCreateRequest {
    AppSecretPasswordValidation: AppSecretPasswordValidation;
}

/**
 * SecretsApi - interface
 * 
 * @export
 * @interface SecretsApiInterface
 */
export interface SecretsApiInterface {
    /**
     * 
     * @param {AppSecretPasswordValidation} AppSecretPasswordValidation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApiInterface
     */
    secretsValidatePasswordCreateRaw(requestParameters: SecretsValidatePasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppSecretPasswordValidationResult>>;

    /**
     */
    secretsValidatePasswordCreate(requestParameters: SecretsValidatePasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppSecretPasswordValidationResult>;

}

/**
 * 
 */
export class SecretsApi extends runtime.BaseAPI implements SecretsApiInterface {

    /**
     */
    async secretsValidatePasswordCreateRaw(requestParameters: SecretsValidatePasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppSecretPasswordValidationResult>> {
        if (requestParameters.AppSecretPasswordValidation === null || requestParameters.AppSecretPasswordValidation === undefined) {
            throw new runtime.RequiredError('AppSecretPasswordValidation','Required parameter requestParameters.AppSecretPasswordValidation was null or undefined when calling secretsValidatePasswordCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/secrets/validate-password/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppSecretPasswordValidationToJSON(requestParameters.AppSecretPasswordValidation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppSecretPasswordValidationResultFromJSON(jsonValue));
    }

    /**
     */
    async secretsValidatePasswordCreate(requestParameters: SecretsValidatePasswordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppSecretPasswordValidationResult> {
        const response = await this.secretsValidatePasswordCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
