/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HostDayPassRedemption } from './HostDayPassRedemption';
import {
    HostDayPassRedemptionFromJSON,
    HostDayPassRedemptionFromJSONTyped,
    HostDayPassRedemptionToJSON,
} from './HostDayPassRedemption';

/**
 * 
 * @export
 * @interface PatchedUserDayPass
 */
export interface PatchedUserDayPass {
    /**
     * 
     * @type {Date}
     * @memberof PatchedUserDayPass
     */
    readonly created_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedUserDayPass
     */
    readonly expires_at: Date;
}

/**
 * Check if a given object implements the PatchedUserDayPass interface.
 */
export function instanceOfPatchedUserDayPass(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created_at" in value;
    isInstance = isInstance && "expires_at" in value;

    return isInstance;
}

export function PatchedUserDayPassFromJSON(json: any): PatchedUserDayPass {
    return PatchedUserDayPassFromJSONTyped(json, false);
}

export function PatchedUserDayPassFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedUserDayPass {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'expires_at': (new Date(json['expires_at'])),
    };
}

export function PatchedUserDayPassToJSON(value?: PatchedUserDayPass | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

