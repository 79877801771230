import { AnalyticsBrowser } from "@june-so/analytics-next"
import { NextRouter } from "next/router"
import { Dispatch, SetStateAction } from "react"

import { OTPAuthenticate, OTPAuthenticateResponse200, OTPRequest, OTPRequestResponse200, User } from "../../sdk"
import { handleUnauthenticatedUser } from "../components/utils/AuthCheck"
import { ENDPOINTS } from "../constants/endpoints"
import { PAGE_ROUTES } from "../constants/routes"
import JSONFormError, { JSONError } from "../errors/JSONFormError"
import { CleanUser } from "../types/User"
import { cleanUserObject } from "../utils/cleaners"

export async function requestOTP<TFieldValues>(
    data: OTPRequest,
    router: NextRouter,
    clearAuth: () => void,
): Promise<void> {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")

    const request = new Request(ENDPOINTS.OTP_REQUEST, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
    })
    const response = await fetch(request)
    const responseJSON = (await response.json()) as OTPRequestResponse200

    if (response.ok) {
        const nextUrl = (router.query?.next as string) || PAGE_ROUTES.HOME
        router.push(
            `${PAGE_ROUTES.AUTH_VERIFY_BASE}?phone_id=${encodeURIComponent(
                responseJSON.phone_id,
            )}&phone_number=${encodeURIComponent(data.phone_number)}&next=${nextUrl}`,
        )
    } else {
        if (response.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            throw new JSONFormError<TFieldValues>({
                status: response.status,
                errors: responseJSON as JSONError<TFieldValues>,
            })
        }
    }
}

export async function verifyOTP<TFieldValues>(
    data: OTPAuthenticate,
    router: NextRouter,
    analytics: AnalyticsBrowser,
    setToken: (value: string) => Promise<void>,
    clearAuth: () => void,
) {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")

    const request = new Request(ENDPOINTS.OTP_VERIFY, { method: "POST", headers: headers, body: JSON.stringify(data) })
    const response = await fetch(request)
    const responseJSON = (await response.json()) as OTPAuthenticateResponse200

    if (response.ok) {
        await setToken(responseJSON.token)

        const nextUrl = (router.query?.next as string) || PAGE_ROUTES.HOME

        if (responseJSON.user_created) {
            analytics?.track("Log In", { first_login: true })
            router.replace(`${PAGE_ROUTES.AUTH_REGISTER}?next=${nextUrl}`)
        } else {
            analytics?.track("Log In", { first_login: false })
            router.replace(nextUrl)
        }
    } else {
        if (response.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            throw new JSONFormError<TFieldValues>({
                status: response.status,
                errors: responseJSON as JSONError<TFieldValues>,
            })
        }
    }
}

export async function fetchUser(token: string): Promise<CleanUser> {
    if (!token) {
        return null
    }

    const headers = new Headers()
    headers.append("Authorization", `Token ${token}`)

    const request = new Request(ENDPOINTS.CURRENT_USER, { method: "GET", headers: headers })
    const response = await fetch(request)

    if (!response.ok) {
        if (response.status === 403) {
            // Permission denied due to a bad token.
            return null
        } else {
            throw new Error("There was an error while trying to load the user.")
        }
    }

    const user = (await response.json()) as User

    return cleanUserObject(user, token)
}

export async function updateUser<TFieldValues>(
    data: TFieldValues,
    router: NextRouter,
    token: string,
    setUser: Dispatch<SetStateAction<CleanUser>>,
    clearAuth: () => void,
) {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Token ${token}`)

    const request = new Request(ENDPOINTS.UPDATE_USER, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(data),
    })
    const response = await fetch(request)
    const responseJSON = (await response.json()) as User

    if (response.ok) {
        const user = cleanUserObject(responseJSON, token)
        setUser(user)
        return user
    } else {
        if (response.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            throw new JSONFormError<TFieldValues>({
                status: response.status,
                errors: responseJSON as JSONError<TFieldValues>,
            })
        }
    }
}

export async function registerUser<TFieldValues>(
    data: TFieldValues,
    router: NextRouter,
    token: string,
    setUser: Dispatch<SetStateAction<CleanUser>>,
    clearAuth: () => void,
) {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Token ${token}`)

    const request = new Request(ENDPOINTS.REGISTER_USER, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(data),
    })
    const response = await fetch(request)
    const responseJSON = (await response.json()) as User

    if (response.ok) {
        const nextUrl = (router.query?.next as string) || PAGE_ROUTES.HOME

        setUser(cleanUserObject(responseJSON, token))

        if (responseJSON.accepted_referral_code) {
            router.replace(`${PAGE_ROUTES.PRO_CLAIM}?next=${nextUrl}`)
        } else if (responseJSON.has_pending_donation) {
            router.replace(PAGE_ROUTES.PRO)
        } else {
            router.replace(nextUrl)
        }
    } else {
        if (response.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            throw new JSONFormError<TFieldValues>({
                status: response.status,
                errors: responseJSON as JSONError<TFieldValues>,
            })
        }
    }
}

export async function setUserDeviceToken(
    deviceToken: string,
    devicePlatform: string,
    router: NextRouter,
    clearAuth: () => void,
    token: string,
) {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Token ${token}`)

    const request = new Request(ENDPOINTS.UPDATE_USER, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({ device_token: deviceToken, device_platform: devicePlatform }),
    })
    const response = await fetch(request)
    const responseJSON = (await response.json()) as User

    if (!response.ok) {
        if (response.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            console.error(`Error while attempting to set user device token. [${response.status}]`, responseJSON)
        }
    }
}

export async function setUserLastAppOpen(router: NextRouter, clearAuth: () => void, token: string) {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Token ${token}`)

    const request = new Request(ENDPOINTS.UPDATE_USER, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({ last_app_open: new Date().toISOString() }),
    })
    const response = await fetch(request)
    const responseJSON = (await response.json()) as User

    if (!response.ok) {
        if (response.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            console.error(`Error while attempting to set user device token. [${response.status}]`, responseJSON)
        }
    }
}

export async function logOutUser(router: NextRouter, clearAuth: () => void, token: string) {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Token ${token}`)

    const request = new Request(ENDPOINTS.LOGOUT, { method: "POST", headers: headers })
    const response = await fetch(request)

    if (response.ok) {
        router.push(PAGE_ROUTES.AUTH_HOME)
    } else {
        if (response.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            throw new Error(`There was an error while attempting to log out.`)
        }
    }
}
