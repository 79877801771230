/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppSecret } from './AppSecret';
import {
    AppSecretFromJSON,
    AppSecretFromJSONTyped,
    AppSecretToJSON,
} from './AppSecret';
import type { SecretTypeEnum } from './SecretTypeEnum';
import {
    SecretTypeEnumFromJSON,
    SecretTypeEnumFromJSONTyped,
    SecretTypeEnumToJSON,
} from './SecretTypeEnum';

/**
 * 
 * @export
 * @interface AppSecretPasswordValidationResultMetadata
 */
export interface AppSecretPasswordValidationResultMetadata {
    /**
     * 
     * @type {string}
     * @memberof AppSecretPasswordValidationResultMetadata
     */
    readonly name: string;
    /**
     * 
     * @type {SecretTypeEnum}
     * @memberof AppSecretPasswordValidationResultMetadata
     */
    readonly secret_type: SecretTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AppSecretPasswordValidationResultMetadata
     */
    readonly destination: string;
}

/**
 * Check if a given object implements the AppSecretPasswordValidationResultMetadata interface.
 */
export function instanceOfAppSecretPasswordValidationResultMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "secret_type" in value;
    isInstance = isInstance && "destination" in value;

    return isInstance;
}

export function AppSecretPasswordValidationResultMetadataFromJSON(json: any): AppSecretPasswordValidationResultMetadata {
    return AppSecretPasswordValidationResultMetadataFromJSONTyped(json, false);
}

export function AppSecretPasswordValidationResultMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppSecretPasswordValidationResultMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'secret_type': SecretTypeEnumFromJSON(json['secret_type']),
        'destination': json['destination'],
    };
}

export function AppSecretPasswordValidationResultMetadataToJSON(value?: AppSecretPasswordValidationResultMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

