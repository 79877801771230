/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Referral,
  ReferralCodeValidation,
  ReferralCodeValidationResult,
} from '../models';
import {
    ReferralFromJSON,
    ReferralToJSON,
    ReferralCodeValidationFromJSON,
    ReferralCodeValidationToJSON,
    ReferralCodeValidationResultFromJSON,
    ReferralCodeValidationResultToJSON,
} from '../models';

export interface ReferralsValidateCodeCreateRequest {
    ReferralCodeValidation: ReferralCodeValidation;
}

/**
 * ReferralsApi - interface
 * 
 * @export
 * @interface ReferralsApiInterface
 */
export interface ReferralsApiInterface {
    /**
     * For each of a user\'s referrals, show the invoice date on which the referral credit is applied.  * Referrals have a `sender_reward_credit_amount` that is applied to the referral sender\'s invoices. * A single referral\'s credit will always be less than or equal to the total of any invoice. * Referral credit is applied to the next invoice with a creation date after the referral\'s conversion date. * If the referral credit exceeds the invoice total, the remaining credit is applied to the next invoice. * If there is more referral credit than invoices, future invoices are stubbed based on the last invoice date.  Returns a dictionary with the referral ID as the key and the date on which credit is applied as the value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    referralsCreditApplicationsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Date; }>>;

    /**
     * For each of a user\'s referrals, show the invoice date on which the referral credit is applied.  * Referrals have a `sender_reward_credit_amount` that is applied to the referral sender\'s invoices. * A single referral\'s credit will always be less than or equal to the total of any invoice. * Referral credit is applied to the next invoice with a creation date after the referral\'s conversion date. * If the referral credit exceeds the invoice total, the remaining credit is applied to the next invoice. * If there is more referral credit than invoices, future invoices are stubbed based on the last invoice date.  Returns a dictionary with the referral ID as the key and the date on which credit is applied as the value.
     */
    referralsCreditApplicationsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Date; }>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    referralsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Referral>>>;

    /**
     */
    referralsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Referral>>;

    /**
     * 
     * @param {ReferralCodeValidation} ReferralCodeValidation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    referralsValidateCodeCreateRaw(requestParameters: ReferralsValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralCodeValidationResult>>;

    /**
     */
    referralsValidateCodeCreate(requestParameters: ReferralsValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralCodeValidationResult>;

}

/**
 * 
 */
export class ReferralsApi extends runtime.BaseAPI implements ReferralsApiInterface {

    /**
     * For each of a user\'s referrals, show the invoice date on which the referral credit is applied.  * Referrals have a `sender_reward_credit_amount` that is applied to the referral sender\'s invoices. * A single referral\'s credit will always be less than or equal to the total of any invoice. * Referral credit is applied to the next invoice with a creation date after the referral\'s conversion date. * If the referral credit exceeds the invoice total, the remaining credit is applied to the next invoice. * If there is more referral credit than invoices, future invoices are stubbed based on the last invoice date.  Returns a dictionary with the referral ID as the key and the date on which credit is applied as the value.
     */
    async referralsCreditApplicationsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Date; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/referrals/credit-applications/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * For each of a user\'s referrals, show the invoice date on which the referral credit is applied.  * Referrals have a `sender_reward_credit_amount` that is applied to the referral sender\'s invoices. * A single referral\'s credit will always be less than or equal to the total of any invoice. * Referral credit is applied to the next invoice with a creation date after the referral\'s conversion date. * If the referral credit exceeds the invoice total, the remaining credit is applied to the next invoice. * If there is more referral credit than invoices, future invoices are stubbed based on the last invoice date.  Returns a dictionary with the referral ID as the key and the date on which credit is applied as the value.
     */
    async referralsCreditApplicationsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Date; }> {
        const response = await this.referralsCreditApplicationsRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referralsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Referral>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/referrals/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferralFromJSON));
    }

    /**
     */
    async referralsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Referral>> {
        const response = await this.referralsListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referralsValidateCodeCreateRaw(requestParameters: ReferralsValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralCodeValidationResult>> {
        if (requestParameters.ReferralCodeValidation === null || requestParameters.ReferralCodeValidation === undefined) {
            throw new runtime.RequiredError('ReferralCodeValidation','Required parameter requestParameters.ReferralCodeValidation was null or undefined when calling referralsValidateCodeCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/referrals/validate-code/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReferralCodeValidationToJSON(requestParameters.ReferralCodeValidation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralCodeValidationResultFromJSON(jsonValue));
    }

    /**
     */
    async referralsValidateCodeCreate(requestParameters: ReferralsValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralCodeValidationResult> {
        const response = await this.referralsValidateCodeCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
