/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Fundraiser,
  FundraiserCodeValidation,
  FundraiserCodeValidationResult,
} from '../models';
import {
    FundraiserFromJSON,
    FundraiserToJSON,
    FundraiserCodeValidationFromJSON,
    FundraiserCodeValidationToJSON,
    FundraiserCodeValidationResultFromJSON,
    FundraiserCodeValidationResultToJSON,
} from '../models';

export interface FundraisersRetrieveRequest {
    id: number;
}

export interface FundraisersValidateCodeCreateRequest {
    FundraiserCodeValidation: FundraiserCodeValidation;
}

/**
 * FundraisersApi - interface
 * 
 * @export
 * @interface FundraisersApiInterface
 */
export interface FundraisersApiInterface {
    /**
     * 
     * @param {number} id A unique integer value identifying this Fundraiser.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundraisersApiInterface
     */
    fundraisersRetrieveRaw(requestParameters: FundraisersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Fundraiser>>;

    /**
     */
    fundraisersRetrieve(requestParameters: FundraisersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Fundraiser>;

    /**
     * 
     * @param {FundraiserCodeValidation} FundraiserCodeValidation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundraisersApiInterface
     */
    fundraisersValidateCodeCreateRaw(requestParameters: FundraisersValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FundraiserCodeValidationResult>>;

    /**
     */
    fundraisersValidateCodeCreate(requestParameters: FundraisersValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FundraiserCodeValidationResult>;

}

/**
 * 
 */
export class FundraisersApi extends runtime.BaseAPI implements FundraisersApiInterface {

    /**
     */
    async fundraisersRetrieveRaw(requestParameters: FundraisersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Fundraiser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fundraisersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/fundraisers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FundraiserFromJSON(jsonValue));
    }

    /**
     */
    async fundraisersRetrieve(requestParameters: FundraisersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Fundraiser> {
        const response = await this.fundraisersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fundraisersValidateCodeCreateRaw(requestParameters: FundraisersValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FundraiserCodeValidationResult>> {
        if (requestParameters.FundraiserCodeValidation === null || requestParameters.FundraiserCodeValidation === undefined) {
            throw new runtime.RequiredError('FundraiserCodeValidation','Required parameter requestParameters.FundraiserCodeValidation was null or undefined when calling fundraisersValidateCodeCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/fundraisers/validate-code/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FundraiserCodeValidationToJSON(requestParameters.FundraiserCodeValidation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FundraiserCodeValidationResultFromJSON(jsonValue));
    }

    /**
     */
    async fundraisersValidateCodeCreate(requestParameters: FundraisersValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FundraiserCodeValidationResult> {
        const response = await this.fundraisersValidateCodeCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
