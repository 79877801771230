/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecretTypeEnum } from './SecretTypeEnum';
import {
    SecretTypeEnumFromJSON,
    SecretTypeEnumFromJSONTyped,
    SecretTypeEnumToJSON,
} from './SecretTypeEnum';

/**
 * 
 * @export
 * @interface AppSecret
 */
export interface AppSecret {
    /**
     * 
     * @type {string}
     * @memberof AppSecret
     */
    readonly name: string;
    /**
     * 
     * @type {SecretTypeEnum}
     * @memberof AppSecret
     */
    readonly secret_type: SecretTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AppSecret
     */
    readonly destination: string;
}

/**
 * Check if a given object implements the AppSecret interface.
 */
export function instanceOfAppSecret(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "secret_type" in value;
    isInstance = isInstance && "destination" in value;

    return isInstance;
}

export function AppSecretFromJSON(json: any): AppSecret {
    return AppSecretFromJSONTyped(json, false);
}

export function AppSecretFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppSecret {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'secret_type': SecretTypeEnumFromJSON(json['secret_type']),
        'destination': json['destination'],
    };
}

export function AppSecretToJSON(value?: AppSecret | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

