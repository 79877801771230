/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FundraiserCodeMetadata } from './FundraiserCodeMetadata';
import {
    FundraiserCodeMetadataFromJSON,
    FundraiserCodeMetadataFromJSONTyped,
    FundraiserCodeMetadataToJSON,
} from './FundraiserCodeMetadata';

/**
 * 
 * @export
 * @interface FundraiserCodeValidationResultMetadata
 */
export interface FundraiserCodeValidationResultMetadata {
    /**
     * 
     * @type {string}
     * @memberof FundraiserCodeValidationResultMetadata
     */
    organization_name: string;
    /**
     * 
     * @type {string}
     * @memberof FundraiserCodeValidationResultMetadata
     */
    fundraiser_name: string;
    /**
     * 
     * @type {string}
     * @memberof FundraiserCodeValidationResultMetadata
     */
    team_name: string;
    /**
     * 
     * @type {Date}
     * @memberof FundraiserCodeValidationResultMetadata
     */
    start_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof FundraiserCodeValidationResultMetadata
     */
    end_at: Date;
    /**
     * 
     * @type {string}
     * @memberof FundraiserCodeValidationResultMetadata
     */
    redemption_code: string;
}

/**
 * Check if a given object implements the FundraiserCodeValidationResultMetadata interface.
 */
export function instanceOfFundraiserCodeValidationResultMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organization_name" in value;
    isInstance = isInstance && "fundraiser_name" in value;
    isInstance = isInstance && "team_name" in value;
    isInstance = isInstance && "start_at" in value;
    isInstance = isInstance && "end_at" in value;
    isInstance = isInstance && "redemption_code" in value;

    return isInstance;
}

export function FundraiserCodeValidationResultMetadataFromJSON(json: any): FundraiserCodeValidationResultMetadata {
    return FundraiserCodeValidationResultMetadataFromJSONTyped(json, false);
}

export function FundraiserCodeValidationResultMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundraiserCodeValidationResultMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization_name': json['organization_name'],
        'fundraiser_name': json['fundraiser_name'],
        'team_name': json['team_name'],
        'start_at': (new Date(json['start_at'])),
        'end_at': (new Date(json['end_at'])),
        'redemption_code': json['redemption_code'],
    };
}

export function FundraiserCodeValidationResultMetadataToJSON(value?: FundraiserCodeValidationResultMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization_name': value.organization_name,
        'fundraiser_name': value.fundraiser_name,
        'team_name': value.team_name,
        'start_at': (value.start_at.toISOString()),
        'end_at': (value.end_at.toISOString()),
        'redemption_code': value.redemption_code,
    };
}

