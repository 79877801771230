import { useRouter } from "next/router"
import { useEffect } from "react"

import Spinner from "../components/loading/Spinner/Spinner"
import { redirectUnregisteredUser } from "../components/utils/AuthCheck"
import { PAGE_ROUTES } from "../constants/routes"
import useUser from "../hooks/User/useUser"
import { checkFreePromoEndsSoon } from "../utils/promotions"

export default function IndexPage() {
    const router = useRouter()
    const { user } = useUser()

    useEffect(() => {
        if (user) {
            // Check if the user is going to be redirected to the registration page to save some router cycles
            const redirected = redirectUnregisteredUser(user, router)

            if (!redirected) {
                const freePromoEndsSoon = checkFreePromoEndsSoon(user)
                if (user.free_promo_active && freePromoEndsSoon && !user.has_active_membership) {
                    // Redirect users to the pro page with promo end date details
                    router.push(PAGE_ROUTES.PRO)
                } else {
                    // Redirect users to the discover page
                    router.push(PAGE_ROUTES.DISCOVER)
                }
            }
        }
    }, [user, router])

    return (
        <main>
            <Spinner />
        </main>
    )
}
