/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HostCodeValidation
 */
export interface HostCodeValidation {
    /**
     * 
     * @type {string}
     * @memberof HostCodeValidation
     */
    host_code: string;
}

/**
 * Check if a given object implements the HostCodeValidation interface.
 */
export function instanceOfHostCodeValidation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "host_code" in value;

    return isInstance;
}

export function HostCodeValidationFromJSON(json: any): HostCodeValidation {
    return HostCodeValidationFromJSONTyped(json, false);
}

export function HostCodeValidationFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostCodeValidation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'host_code': json['host_code'],
    };
}

export function HostCodeValidationToJSON(value?: HostCodeValidation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'host_code': value.host_code,
    };
}

