/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HostCodeMetadata } from './HostCodeMetadata';
import {
    HostCodeMetadataFromJSON,
    HostCodeMetadataFromJSONTyped,
    HostCodeMetadataToJSON,
} from './HostCodeMetadata';

/**
 * 
 * @export
 * @interface HostCodeValidationResultMetadata
 */
export interface HostCodeValidationResultMetadata {
    /**
     * 
     * @type {string}
     * @memberof HostCodeValidationResultMetadata
     */
    host_name: string;
    /**
     * 
     * @type {number}
     * @memberof HostCodeValidationResultMetadata
     */
    day_pass_days: number;
}

/**
 * Check if a given object implements the HostCodeValidationResultMetadata interface.
 */
export function instanceOfHostCodeValidationResultMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "host_name" in value;
    isInstance = isInstance && "day_pass_days" in value;

    return isInstance;
}

export function HostCodeValidationResultMetadataFromJSON(json: any): HostCodeValidationResultMetadata {
    return HostCodeValidationResultMetadataFromJSONTyped(json, false);
}

export function HostCodeValidationResultMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostCodeValidationResultMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'host_name': json['host_name'],
        'day_pass_days': json['day_pass_days'],
    };
}

export function HostCodeValidationResultMetadataToJSON(value?: HostCodeValidationResultMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'host_name': value.host_name,
        'day_pass_days': value.day_pass_days,
    };
}

